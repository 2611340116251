const backEnd = "https://backendsurvey.clitri.ir/api";
const clitri = "https://clitri.ir";

// auth requests
const checkTokenApi = `${backEnd}/check-token`;
const checkTokenRct = `${clitri}/api/check-token`;
const setLifeTime = `${backEnd}/set-lifeTime`;
const getDataRct = `${clitri}/api/get-data`;
const storeUser = `${backEnd}/store-user`;
const refreshCheck = `${backEnd}/refresh-check`;
const redirectRct = `${clitri}/login`;
const getCounts = `${backEnd}/survey/detail`;

// survet requests
const getSurveys = `${backEnd}/surveys`;
const surveyStore = `${backEnd}/survey/store`;
const surveyFetch = `${backEnd}/survey/fetch`;
const surveyUpdate = `${backEnd}/survey/update`;
const surveyDelete = `${backEnd}/survey/delete`;
const surveyStatus = `${backEnd}/survey/update/status`;
const getSurveyAnswers = `${backEnd}/survey/answers`;
const getSurveyAnswer = `${backEnd}/survey/answer`;
const getSurveysPdf = `${backEnd}/survey/pdf`;
const getSurveysExcel = `${backEnd}/survey/excel`;
const getAnswerPdf = `${backEnd}/answers/pdf`;
const getAnswerExcel = `${backEnd}/answers/excel`;
const getAllAnswers = `${backEnd}/answers-detail/pdf`;
const getAnalysisList = `${backEnd}/finished-surveys`;

// survey analysis
const getFileSurvey = `${backEnd}/answers/csv`;

export {
  checkTokenApi,
  checkTokenRct,
  setLifeTime,
  getDataRct,
  storeUser,
  refreshCheck,
  redirectRct,
  surveyStore,
  surveyFetch,
  surveyUpdate,
  surveyDelete,
  surveyStatus,
  getSurveys,
  getSurveyAnswers,
  getSurveyAnswer,
  getSurveysPdf,
  getSurveysExcel,
  getAnswerPdf,
  getAnswerExcel,
  getAllAnswers,
  getCounts,
  getFileSurvey,
  getAnalysisList,
};
