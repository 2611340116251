import "./styles/preloader.css";

function Preloader() {
  return (
    <div className="preloader">
      {/* eslint-disable-next-line */}
      <div className="preloader_image"></div>
    </div>
  );
}

export default Preloader;
