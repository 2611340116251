// token access

import React, { useState, useEffect, useMemo, Suspense } from "react";

// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
// import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
// import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";

// Material Dashboard 2 React contexts
// eslint-disable-next-line
import {
  useMaterialUIController,
  setMiniSidenav,
  setToken,
  setPermission,
  setUser,
  removeSessions,
} from "context";

// urls
import {
  checkTokenApi,
  checkTokenRct,
  setLifeTime,
  getDataRct,
  storeUser,
  refreshCheck,
  redirectRct,
} from "assets/requestUrl/Urls";

import axios from "axios";

// Images
import brandWhite from "assets/images/clin.t2 copy.png";

// App style
import "./App.css";
import CookieService from "encrypted-cookie";
import Preloader from "examples/Preloader";

const SurveyForm = React.lazy(() => import("layouts/SurveyForm"));

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { token, permissions, miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  function findCommonElemets(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (findCommonElemets(route.access, permissions)) {
        if (route.children) {
          return (
            <Route path={route.route} key={route}>
              <Route index element={route.component} />
              {route.children.map((item) => (
                <Route path={route.route + item.route} key={item} element={item.component} />
              ))}
            </Route>
          );
        }

        if (route.route) {
          return <Route path={route.route} key={route} element={route.component} />;
        }
      }
      return null;
    });

  const location = useLocation();

  const checkRoutes = (allRoutes) => {
    const currentLocation = location.pathname;
    let check = true;

    if (currentLocation.includes("survey-form")) return false;

    allRoutes.forEach((route) => {
      if (currentLocation === route.route) {
        check = check && false;
      }

      if (route.children) {
        route.children.forEach((childRoute) => {
          // eslint-disable-next-line
          if (currentLocation.includes(route.route + "/" + childRoute.route.split("/")[0])) {
            check = check && false;
          }
        });
      }

      check = check && true;
    });

    return check;
  };

  const accessToken = () => CookieService.getCookie("token");
  const perm = () => CookieService.getArrayCookie("perms");
  const studyId = () => CookieService.getCookie("study_id");

  useEffect(() => {
    // eslint-disable-next-line
    const study = parseInt(location.pathname.split("/")[2]);
    const userToken = location.pathname.split("/")[1];

    if (!location.pathname.includes("survey-form")) {
      if (
        !accessToken() ||
        !perm() ||
        !studyId() ||
        (userToken === accessToken() && studyId() !== study)
      ) {
        axios
          .get(checkTokenApi, {
            headers: {
              Authorization: userToken,
              studyId: study,
            },
          })
          .then((response) => {
            const data = { ...response.data };
            axios
              .get(checkTokenRct, {
                headers: {
                  Authorization: data.user.token,
                  studyId: study,
                },
              })
              .then((response1) => {
                axios
                  .post(
                    setLifeTime,
                    {
                      // eslint-disable-next-line
                      lifeTime: parseInt(response1.data.lifeTime),
                    },
                    {
                      headers: {
                        Authorization: data.user.token,
                        studyId: study,
                      },
                    }
                  )
                  .then(() => {
                    setToken(dispatch, data.user.token);
                    CookieService.setCookie("token", data.user.token);
                    setPermission(dispatch, data.permissions);
                    CookieService.setArrayCookie("perms", data.permissions);
                    setUser(dispatch, data.user);
                    CookieService.setCookie("study_id", `${study}`);
                  })
                  .catch((error2) => {
                    removeSessions();
                    if (error2.response.status === 419) {
                      window.location.href = redirectRct;
                    }
                  });
              })
              .catch((error1) => {
                removeSessions();
                if (error1.response.status === 419) {
                  window.location.href = redirectRct;
                }
              });
          })
          .catch(() => {
            axios
              .get(getDataRct, {
                headers: {
                  Authorization: userToken,
                  studyId: study,
                },
              })
              .then((response2) => {
                axios
                  .post(
                    storeUser,
                    {
                      data: JSON.stringify({
                        ...response2.data,
                      }),
                    },
                    {
                      headers: {
                        Authorization: response2.data.token,
                        lifeTime: response2.data.lifeTime,
                        studyId: study,
                        AccessControlAllowOrigin: "*",
                      },
                    }
                  )
                  .then(() => {
                    setToken(dispatch, response2.data.token);
                    CookieService.setCookie("token", response2.data.token);
                    setPermission(dispatch, response2.data.permissions);
                    CookieService.setArrayCookie("perms", response2.data.permissions);
                    setUser(dispatch, response2.data.user);
                    CookieService.setCookie("study_id", `${study}`);
                  })
                  .catch((error1) => {
                    removeSessions();
                    if (error1.response.status === 419) {
                      window.location.href = redirectRct;
                    }
                  });
              })
              .catch((error1) => {
                removeSessions();
                if (error1.response.status === 419) {
                  window.location.href = redirectRct;
                }
              });
          });
      } else {
        axios
          .get(refreshCheck, {
            headers: { Authorization: accessToken(), studyId: studyId() },
          })
          .then((response) => {
            setPermission(dispatch, response.data.permissions);
            CookieService.setArrayCookie("perms", response.data.permissions);
            setUser(dispatch, response.data.user);
            CookieService.setCookie("study_id", `${response.data.studyId}`);
          })
          .catch((error) => {
            removeSessions();
            if (error.response.status === 419) {
              window.location.href = redirectRct;
            }
          });
      }
    }
  }, []);

  if (!token && !accessToken() && !location.pathname.includes("survey-form")) return <Preloader />;

  return direction === "rtl" && !location.pathname.includes("/survey-form") ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <Sidenav
            color={sidenavColor}
            brand={brandWhite}
            brandName="Material Dashboard 2"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        <Routes>
          {getRoutes(routes)}
          {checkRoutes(routes) &&
            (location.pathname.split("/")[3] === "action" ? (
              <Route path="*" element={<Navigate to="/questionnairs" />} />
            ) : (
              <Route path="*" element={<Navigate to="/questionnair" />} />
            ))}
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        <Routes>
          <Route path="/survey-form">
            <Route
              path="*"
              element={
                <Suspense fallback={<Preloader />}>
                  <SurveyForm />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  );
}
