// hooks

import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Collapse } from "@mui/material";

function SidenavCollapse({
  access,
  icon,
  name,
  routeKey,
  collapse,
  colActive,
  active,
  route,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { permissions, miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } =
    controller;
  const [openList, setOpenList] = useState(false);

  const hasCollapse = () => {
    if (collapse) {
      return openList ? (
        <ExpandLess
          onClick={(event) => {
            event.preventDefault();
            setOpenList(!openList);
          }}
        />
      ) : (
        <ExpandMore
          onClick={(event) => {
            event.preventDefault();
            setOpenList(!openList);
          }}
        />
      );
    }
    return null;
  };

  function findCommonElemets(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }
  // eslint-disable-next-line
  return findCommonElemets(access, permissions) ? (
    route.includes("http") ? (
      <a href={route}>
        <ListItem component="li">
          <MDBox
            {...rest}
            sx={(theme) =>
              collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <ListItemIcon
              sx={(theme) =>
                collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
              }
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>

            <ListItemText
              primary={name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active,
                })
              }
            />
            {hasCollapse()}
          </MDBox>
        </ListItem>
      </a>
    ) : (
      <>
        <NavLink key={routeKey} to={route}>
          <ListItem component="li">
            <MDBox
              {...rest}
              sx={(theme) =>
                collapseItem(theme, {
                  active,
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                  sidenavColor,
                })
              }
            >
              <ListItemIcon
                sx={(theme) =>
                  collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                }
              >
                {typeof icon === "string" ? (
                  <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                ) : (
                  icon
                )}
              </ListItemIcon>

              <ListItemText
                primary={name}
                sx={(theme) =>
                  collapseText(theme, {
                    miniSidenav,
                    transparentSidenav,
                    whiteSidenav,
                    active,
                  })
                }
              />
              {hasCollapse()}
            </MDBox>
          </ListItem>
        </NavLink>
        <Collapse in={openList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {collapse
              ? collapse.map((item) => {
                  const nestedActive = item.key === colActive;

                  return (
                    <NavLink
                      to={route + item.route}
                      key={item.key}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <ListItem sx={{ pl: 2 }}>
                        <MDBox
                          {...rest}
                          sx={(theme) =>
                            collapseItem(theme, {
                              active: nestedActive,
                              transparentSidenav,
                              whiteSidenav,
                              darkMode,
                              sidenavColor,
                            })
                          }
                        >
                          <ListItemIcon
                            sx={(theme) =>
                              collapseIconBox(theme, {
                                transparentSidenav,
                                whiteSidenav,
                                darkMode,
                                active: nestedActive,
                              })
                            }
                          >
                            {typeof item.icon === "string" ? (
                              <Icon sx={(theme) => collapseIcon(theme, { active: nestedActive })}>
                                {item.icon}
                              </Icon>
                            ) : (
                              item.icon
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.name}
                            sx={(theme) =>
                              collapseText(theme, {
                                miniSidenav,
                                transparentSidenav,
                                whiteSidenav,
                                active: nestedActive,
                              })
                            }
                          />
                        </MDBox>
                      </ListItem>
                    </NavLink>
                  );
                })
              : ""}
          </List>
        </Collapse>
      </>
    )
  ) : null;
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  collapse: null,
  colActive: null,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  routeKey: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  colActive: PropTypes.string,
  active: PropTypes.bool,
  collapse: PropTypes.arrayOf(PropTypes.object),
};

export default SidenavCollapse;
