/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "TOKEN": {
      return { ...state, token: action.value };
    }
    case "USER": {
      return { ...state, user: { ...action.value } };
    }
    case "PERMISSIONS": {
      return { ...state, permissions: action.value };
    }
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    // case "TRANSPARENT_NAVBAR": {
    //   return { ...state, transparentNavbar: action.value };
    // }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "ANALYS_FILE": {
      return { ...state, analysFile: action.value };
    }
    case "ANALYS_RESULT": {
      return { ...state, analysResult: action.value };
    }
    case "DATA_CHART":
      return { ...state, dataChart: action.value };
    case "OPEN_CHART":
      return { ...state, openChart: action.value };
    case "SHOW_PROCCES":
      return { ...state, showProcces: action.value };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    token: null,
    user: null,
    permissions: [],
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "success",
    // transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "rtl",
    layout: "dashboard",
    darkMode: false,
    analysFile: null,
    analysResult: null,
    dataChart: null,
    openChart: false,
    showProcces: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setToken = (dispatch, value) => dispatch({ type: "TOKEN", value });
const setUser = (dispatch, value) => dispatch({ type: "USER", value });
const setPermission = (dispatch, value) => dispatch({ type: "PERMISSIONS", value });
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setAnalysFile = (dispatch, value) => dispatch({ type: "ANALYS_FILE", value });
const setAnalysResult = (dispatch, value) => dispatch({ type: "ANALYS_RESULT", value });
const setDataChart = (dispatch, value) => dispatch({ type: "DATA_CHART", value });
const setOpenChart = (dispatch, value) => dispatch({ type: "OPEN_CHART", value });
const setShowProcces = (dispatch, value) => dispatch({ type: "SHOW_PROCCES", value });

const removeSessions = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("permissions");
  sessionStorage.removeItem("study_id");
};

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setToken,
  setUser,
  setPermission,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  // setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  removeSessions,
  setAnalysFile,
  setAnalysResult,
  setDataChart,
  setOpenChart,
  setShowProcces,
};
