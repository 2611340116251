/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import React, { Suspense } from "react";

// @mui icons
import Icon from "@mui/material/Icon";
import RuleRoundedIcon from "@mui/icons-material/RuleRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import AnalyticsIcon from "@mui/icons-material/Analytics";

// preloader
import Preloader from "examples/Preloader";

// Material Dashboard 2 React layouts
const Dashboard = React.lazy(() => import("layouts/dashboard"));
const Tables = React.lazy(() => import("layouts/tables"));
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
const Profile = React.lazy(() => import("layouts/profile"));
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

// import Tools from "layouts/tools";
// import Calendar from "layouts/tools/Calendar";
// import Questionnair from "layouts/tools/Questionnair";
const SurveyCreatorWidget = React.lazy(() => import("layouts/SurveyCreator/SurveyCreatorWidget"));
const AnalysisList = React.lazy(() => import("layouts/Analysis/AnalysisList"));
const Analysis = React.lazy(() => import("layouts/Analysis"));
const SurveyCreatorPreview = React.lazy(() =>
  import("layouts/tables/components/SurveyCreator/SurveyCreatorPreview")
);
const SurveyCreatorEdit = React.lazy(() =>
  import("layouts/tables/components/SurveyCreator/SurveyCreatorEdit")
);
const AnswersTable = React.lazy(() =>
  import("./layouts/tables/components/answersTable/AnswersTable")
);
const ReviewAnswers = React.lazy(() =>
  import("layouts/tables/components/answersTable/ReviewAnswers")
);

const routes = [
  {
    access: ["read-survey", "create-survey", "edit-survey", "delete-survey"],
    type: "collapse",
    name: "داشبورد",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: (
      <Suspense fallback={<Preloader />}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    access: ["read-survey", "create-survey", "edit-survey", "delete-survey"],
    type: "collapse",
    name: "پروفایل",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: (
      <Suspense fallback={<Preloader />}>
        <Profile />
      </Suspense>
    ),
  },
  {
    access: ["create-survey", "edit-survey"],
    type: "collapse",
    name: "پرسشنامه ساز",
    key: "questionnair",
    icon: <RuleRoundedIcon />,
    route: "/questionnair",
    component: (
      <Suspense fallback={<Preloader />}>
        <SurveyCreatorWidget />
      </Suspense>
    ),
  },
  {
    access: ["read-survey", "create-survey", "edit-survey", "delete-survey"],
    type: "collapse",
    name: "لیست پرسشنامه ها",
    key: "questionnairs",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/questionnairs",
    component: (
      <Suspense fallback={<Preloader />}>
        <Tables />
      </Suspense>
    ),
    children: [
      {
        type: "collapse",
        key: "questionnair",
        route: "edit/:questionnairId",
        component: (
          <Suspense fallback={<Preloader />}>
            <SurveyCreatorEdit />
          </Suspense>
        ),
      },
      {
        type: "collapse",
        key: "questionnair",
        route: "show/:questionnairId",
        component: (
          <Suspense fallback={<Preloader />}>
            <SurveyCreatorPreview />
          </Suspense>
        ),
      },
      {
        type: "collapse",
        key: "questionnair",
        route: "answers/:questionnairId",
        component: (
          <Suspense fallback={<Preloader />}>
            <AnswersTable />
          </Suspense>
        ),
      },
      {
        type: "collapse",
        key: "questionnair",
        route: "answers/show/:answerId",
        component: (
          <Suspense fallback={<Preloader />}>
            <ReviewAnswers />
          </Suspense>
        ),
      },
    ],
  },
  {
    access: ["read-survey", "create-survey", "edit-survey", "delete-survey"],
    type: "collapse",
    name: "آنالیز پرسشنامه ها",
    key: "analysis",
    icon: <AnalyticsIcon />,
    route: "/analysis",
    component: (
      <Suspense fallback={<Preloader />}>
        <AnalysisList />
      </Suspense>
    ),
    children: [
      {
        type: "collapse",
        key: "analysis",
        route: "/:questionnairId",
        component: (
          <Suspense fallback={<Preloader />}>
            <Analysis />
          </Suspense>
        ),
      },
    ],
  },
];

export default routes;
